body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.html .body {
  background-color: #7a8dc7;
}

.request-popup .leaflet-popup-content-wrapper{
  background: transparent !important;
  box-shadow:0 0px 0px rgba(0,0,0,0.0) !important
}

.leaflet-popup-content {
  margin: 0 !important;
}
