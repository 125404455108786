.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.slick-prev:before,
.slick-next:before {
  display: none
}

.slick-arrow {
  height: calc(100% + 3px) !important;
  width: 60px !important;
  z-index: 1;
}

.slick-prev {
    left: 0 !important;
  }
.slick-next {
  right: 0 !important;
  }

.slick-list {
  overflow-x: clip !important;
  overflow-y: unset !important;
}

.slick-track {
    display: flex !important;
        /* gap: 10px */
}

.slick-slide {
    height: inherit !important;
}

.slick-slide > div {
  height: 100%;
}

/* .adsbygoogle {
  border: 1px solid red;
} */

.marker-icon{
  border-radius: 50%;
  height: 50px;
  width: 50px;
  z-index: 1000 !important;
  border: 2px solid #19304c
}

.leaflet-div-icon {
  background: unset !important;
  border: unset !important;
}

.leaflet-popup-close-button {
  display: none; 
}

.marker-cluster-small {
  background-color: rgba(128, 175, 225, 0.5) !important;
}

.marker-cluster-small div {
  background-color: rgba(72, 149, 232, 1) !important;
  color: white;
  font-weight: 800 !important;
}
